<template>
  <div v-if="hasProducts" class="w-full">
    <BlockTitle :subtitle="subtitle" :title="title" />

    <ProductCardCollection
      :block-position="blockPosition"
      layout="carousel"
      :products="products || []"
      provider="algolia"
      :tracking="{
        productComponent: 'Products',
        ...tracking,
      }"
    />

    <div class="mt-8 flex justify-center">
      <CTALink :cta-link="ctaLink" width="fixed" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { ProductsBlockApiProps } from '@backmarket/http-api/src/api-specs-content/models/products-block'
import ProductCardCollection from '@backmarket/nuxt-layer-recommendation/ProductCardCollection.vue'

import type { ContentBlockProps } from '../../models/content-block'
import { useSearchProducts } from '../../products/useSearchProducts'
import BlockTitle from '../../shared-components/BlockTitle/BlockTitle.vue'
import CTALink from '../../shared-components/CTALink/CTALink.vue'

export type ProductsBlockProps = Partial<ProductsBlockApiProps> &
  ContentBlockProps
const props = withDefaults(defineProps<ProductsBlockProps>(), {
  productQueries: () => [],
  title: '',
  subtitle: '',
})

// This event is common to every CMS block in order to hide the block containers
// on demand
defineEmits(['error'])

const { data: products } = useSearchProducts(props)

const hasProducts = computed(() => products.value && products.value.length > 0)
</script>
